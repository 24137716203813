import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';


@Injectable()
export class ThicknessService {
  thickness: boolean = false;

  private withInfo(closure: (options: HttpHeaders) => void): void {
    let options = new HttpHeaders();
    options = options.set('Content-Type', 'application/json; charset=utf-8');

    closure(options);
  }

  constructor(
    private _http: HttpClient,
    private auth: AuthService,
  ) { }

  public getThickness() {
    this.hasThicknessCRM(this.auth.username()).then((data) => {
      this.thickness = data ? true : false;
    });
  }

  public hasThickness(): boolean {
    return this.thickness;
  }


  public hasThicknessCRM(shop_id: string) {
    return new Promise((resolve, reject) => {
      this._http.get(environment.api.crm + "/shop_products/" + shop_id)
        .subscribe(
          (value: any) => {
            if (value.thickness != null) {
              resolve(true)
            } else {
              resolve(false)
            }
          },
          (error: any) => {
            resolve(false)
          }
        )
    });
  }

  private openThicknessPage(tag_id: string = null): void {
    const url = tag_id == null
      ? environment.api.thickness
      : environment.api.thickness.concat("?fitid=").concat(tag_id.toString());
    window.open(url, "_blank").focus();
  }

  private handleLoginRequest(options: HttpHeaders, tag_id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this._http.get(
        environment.api.thickness_api.concat("login"),
        { headers: options, withCredentials: true }
      ).subscribe(
        () => {
          this.openThicknessPage(tag_id);
          resolve();
        },
        (error) => {
          console.log("Error on Thickness login", error);
          reject(error);
        }
      );
    });
  }

  private goToThicknessPage(options: HttpHeaders, tag_id: string = null): Promise<void> {
    return new Promise((resolve, reject) => {
      if (tag_id == null) {
        this.handleLoginRequest(options, tag_id).then(resolve).catch(reject);
      } else {
        const url_fitid = environment.api.measures.concat(tag_id.toString());
        this.withInfo((options) => {
          options = options.set('Content-Type', 'application/json');
          this._http.get(url_fitid, { headers: options }).subscribe(
            () => {
              this.handleLoginRequest(options, tag_id).then(resolve).catch(reject);
            },
            (error) => {
              console.log("Error fetching measure data", error);
              reject(error);
            }
          );
        });
      }
    });
  }

  public goToThickness(tag_id: string = null): Promise<String> {
    return new Promise((resolve, reject) => {
      const options = new HttpHeaders().set('Content-Type', 'application/json');
      this.goToThicknessPage(options, tag_id)
        .then(() => resolve("done"))
        .catch((error) => {
          console.log("Error on Thickness login", error);
          reject("done");
        });
    });
  }

}