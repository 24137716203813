import { Component, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import * as $ from 'jquery';
import { AuthService } from '../auth/auth.service';
import { ThicknessService } from '../services/thickness.service';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent {

  @Input() logo_img: string;
  private currentOption: string = 'home';
  private isMadeForYou: boolean;
  private isOpeningThickness: boolean = false;

  constructor(
    private dialog: MatDialog,
    private auth: AuthService,
    private thickness_service: ThicknessService
  ) { }

  ngOnInit() {
    const url_to_read: string = window.location.href;
    this.isMadeForYou = url_to_read.includes("madeforyouconcept");
  }

  @Output() onNavigationEvent = new EventEmitter<string>();
  onNavigation($event) {
    let clickedElement = $event.target || $event.srcElement;
    this.onNavigationEvent.emit($(clickedElement).data('value'));
    this.currentOption = $(clickedElement).data('value')
  }

  private showSpinnerOnThickness(show: boolean) {
    if (show) {
      this.isOpeningThickness = true
    } else {
      this.isOpeningThickness = false
    }
  }

  private onThickness(): void {
    this.showSpinnerOnThickness(true)
    this.thickness_service.goToThickness().then(
      () =>{
        this.showSpinnerOnThickness(false)
      }
      )
  };
}